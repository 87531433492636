export default {
  actions: {
    load: 'load',
    click: 'click_button',
    click_banner: 'click_banner_carrusel',
    click_just_banner: 'click_banner',
    click_link: 'click_link',
    click_link_login: 'click_link login',
    click_link_header: 'click_link header',
    click_link_preheader: 'click_link preheader',
    click_footer: 'click_link footer',
    laod_error: 'load_error_page',
    not_found: 'pagina_no_encontrada',
    click_tab: 'click_tab_section',
    click_filtro: 'click_filtro_ordenar',
    click_activar_promocion: 'click_activar_promocion',
    click_activar_descuento: 'click_activar_descuento',
    click_detalle_promocion: 'click_detalle_promocion',
    click_detalle_descuento: 'click_detalle_descuento',
    click_activar_promocion_detalle: 'click_activar_promocion_detalle',
    click_activar_descuento_detalle: 'click_activar_descuento_detalle',
    click_cerrar_promocion: 'click_cerrar_promocion',
    click_cerrar_descuento: 'click_cerrar_descuento',
    click_eliminar_promocion: 'click_eliminar_promocion',
    click_eliminar_descuento: 'click_eliminar_descuento',
    click_descargar_cupon_imagen: 'click_descargar_cupon_imagen',
    click_completados_paginacion: 'click_completados_paginacion',
    click_filtro_completadas: 'click_filtro_completadas',
    click_detalle_desafio: 'click_detalle_desafio',
    click_cerrar_desafio: 'click_cerrar_desafio',
    click_activar_desafio: 'click_activar_desafio',
    click_activar_desafio_detalle: 'click_activar_desafio_detalle',
    click_filtro_lateral: 'click_filtro_lateral',
    click_banner_inferior: 'click_banner_inferior',
    disponibilidad_shipping_method: 'disponibilidad_shipping_method',
    click_banner_superior: 'click_banner_superior',
    open_tyc: 'open_popup_tyc',
    click_button_popup: 'click_popup_button',
    submit_form_success: 'submit_form_success',
    form_submit: 'form_submit',
    submit_form_error: 'submit_form_error',
    load_offers_by_hash: 'load_offers_by_hash'
  },
  categories: {
    site: 'site',
    accesibilidad: 'accesibilidad',
    buscador: 'buscador',
    contrasegna: 'contraseña',
    puntos: 'puntos',
    canjear: 'canjear',
    canje: 'canje',
    mis_canjes: 'mis_canjes',
    usuarioNuevo: 'usuario nuevo',
    producto: 'producto',
    checkout: 'checkout',
    siteMap: 'mapa del sitio',
    login: 'login',
    cuenta: 'cuenta',
    tienda: 'tienda',
    listado: 'listado',
    listados: 'listados',
    menuProductos: 'menú productos',
    home: 'home',
    informaciones: 'informaciones',
    contacto: 'contacto',
    vitrina: 'vitrina',
    notFound: 'pagina no encontrada',
    paginaError: 'pagina de error',
    errorServidor: 'error en servidor',
    header: 'header',
    preHeader: 'preheader',
    error: 'error',
    ofertas: 'ofertas personalizadas',
    popup: 'popup',
    click_banner_category: 'click_banner_categoria'
  },
  event: {
    banner: 'banner_click',
    user: 'user_action',
    website: 'website_action',
    productoCheckout: 'product_checkout_1',
    productoCheckout2: 'product_checkout_2',
    tienda: 'tienda_view',
    tiendaClick: 'product_tienda_click',
    tiendaView: 'product_tienda_view',
    productView: 'product_view',
    productClick: 'product_click',
    productCategoryView: 'category_view',
    productAddToCart: 'product_add_to_cart',
    error: 'error',
    filterPoints: 'filter_points',
    submit_form_success: 'submit_form_success',
    link_paris: 'link_paris'
  },
  names: {
    consultaPuntos: '¿Cuántos puntos tengo?',
    confirmarCanje: 'Confirmación de tu canje',
    tienda: 'Cátalogo Tiendas'
  }
};