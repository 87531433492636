import isObject from 'lodash/isObject';
var bindUrlImage = function bindUrlImage(urlImage) {
  var _urlImage$imageDetail;
  return isObject(urlImage) ? (_urlImage$imageDetail = urlImage.imageDetail1) === null || _urlImage$imageDetail === void 0 ? void 0 : _urlImage$imageDetail.url : '';
};
export default (function (item, isCatalog, isFeaturedSection) {
  var _item$productImage, _item$productImage$im;
  return isObject(item) ? {
    altText: item.productName,
    prefijo: item.prefix,
    copago: item.copayPrice,
    puntosPlata1: item.pointsforMoney1,
    id: item.id,
    urlImagen: bindUrlImage(item.productImage, isCatalog, isFeaturedSection),
    nombre: item.productName,
    puntos: item.point ? item.point : item.points,
    tienda: item.store,
    cantidadProductos: item.quantityUnits,
    sku: item.productSKU,
    descripcionLarga: item.productDescription,
    condicionesDeCanje: item.exchangeConditions,
    imagenes: [(_item$productImage = item.productImage) === null || _item$productImage === void 0 ? void 0 : (_item$productImage$im = _item$productImage.imageDetail1) === null || _item$productImage$im === void 0 ? void 0 : _item$productImage$im.url]
  } : {};
});