import _toConsumableArray from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import normalizeUri from '@cencosud/puntos-core/src/features/common/url/normalizeUri';

/**
 * getSubcategoria
 * @param {array} categorias - array de objetos con las categorias totales
 * @param {string} categoria - nombre de la subcategoria
 * @returns Devuelve un objeto subcategoria
 */
export default (function (categorias, subcategoria) {
  var _ref, _ref$flat, _categorias$filter;
  var cat = (_ref = [].concat(_toConsumableArray(categorias === null || categorias === void 0 ? void 0 : (_categorias$filter = categorias.filter(function (categoria) {
    return categoria.nombre.toLowerCase() !== 'canjes en tiendas';
  })) === null || _categorias$filter === void 0 ? void 0 : _categorias$filter.map(function (categoria) {
    return categoria.subcategorias;
  })), _toConsumableArray(categorias))) === null || _ref === void 0 ? void 0 : (_ref$flat = _ref.flat()) === null || _ref$flat === void 0 ? void 0 : _ref$flat.find(function (subCategoria) {
    return normalizeUri(subCategoria.nombre) === normalizeUri(subcategoria);
  });
  if (!cat) {
    var _categorias$filter2;
    cat = categorias === null || categorias === void 0 ? void 0 : (_categorias$filter2 = categorias.filter(function (categoria) {
      return categoria.nombre.toLowerCase() !== 'canjes en tiendas';
    })) === null || _categorias$filter2 === void 0 ? void 0 : _categorias$filter2.reduce(function (prev, categoria) {
      return Object.assign(prev, categoria.subcategorias.find(function (subcat) {
        return subcat.subcategorias.find(function (ssc) {
          return ssc.nombreSubCategoria === subcategoria;
        });
      }));
    }, {});
  }
  return cat;
});