import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { isNil, isNaN, isUndefined } from 'lodash';
import Uri from 'urijs/src/URI';
import methods from './httpMethods';
import apiEndpointCreators from './apiEndpointCreators';
var createEndpoint = apiEndpointCreators.ContentV3Endpoint;
var createProductosEndpoint = function createProductosEndpoint(_ref) {
  var page = _ref.page,
    size = _ref.size,
    isDescendingOrder = _ref.isDescendingOrder,
    productName = _ref.productName,
    from = _ref.from,
    to = _ref.to,
    ofertas = _ref.ofertas,
    nuevos = _ref.nuevos,
    categoria = _ref.categoria,
    exclude = _ref.exclude;
  var esPrime = localStorage.getItem('esPrime');
  return createEndpoint({
    method: methods.get,
    uri: new Uri('/public/products').search(_objectSpread(_objectSpread(_objectSpread({
      skip: isNaN(page * size) ? 0 : page * size,
      limit: size,
      order: function () {
        if (isNil(isDescendingOrder)) {
          return undefined;
        }
        return isDescendingOrder ? "-fields.".concat(esPrime ? 'priceLoyaltyClient' : 'regularPoints') // priceLoyaltyClient
        : "fields.".concat(esPrime ? 'priceLoyaltyClient' : 'regularPoints');
      }()
    }, isNil(categoria) ? {} : {
      'fields[category.sys.id]': "".concat(categoria.id)
    }), isUndefined(from) || isNil(from) || isNaN(from) || from === 'NaN' ? {} : {
      'fields[regularPoints[gt]]': "".concat(from)
    }), isUndefined(to) || isNil(to) || isNaN(to) || to === 'NaN' ? {} : {
      'fields[regularPoints[lte]]': "".concat(to)
    }))
  });
};
var createExchangeProductsEndpoint = function createExchangeProductsEndpoint(_ref2) {
  var page = _ref2.page,
    size = _ref2.size,
    from = _ref2.from,
    to = _ref2.to,
    exchangeType = _ref2.exchangeType,
    category = _ref2.category,
    store = _ref2.store,
    asc = _ref2.asc,
    query = _ref2.query;
  var esPrime = localStorage.getItem('esPrime');
  return createEndpoint({
    method: methods.get,
    uri: new Uri('/public/exchange-products').search(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
      skip: isNaN(page * size) ? 0 : page * size,
      limit: size,
      order: function () {
        if (isNil(asc)) {
          return undefined;
        }
        return asc ? "-fields.".concat(esPrime ? 'priceLoyaltyClient' : 'regularPoints') // priceLoyaltyClient
        : "fields.".concat(esPrime ? 'priceLoyaltyClient' : 'regularPoints');
      }()
    }, isNil(exchangeType) ? {} : {
      'fields[exchangeType]': "".concat(exchangeType)
    }), isNil(store) ? {} : {
      'fields[store]': "".concat(store)
    }), isNil(query) ? {} : {
      query: "".concat(query)
    }), isNil(category) ? {} : {
      'fields[category.sys.id]': "".concat(category)
    }), isUndefined(from) || isNil(from) || isNaN(from) || from === 'NaN' ? {} : {
      'fields[regularPoints[gte]]': "".concat(from)
    }), isUndefined(to) || isNil(to) || isNaN(to) || to === 'NaN' ? {} : {
      'fields[regularPoints[lte]]': "".concat(to)
    }))
  });
};
export default {
  basesLegales: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/public/legal-bases/1Y1KjWYCrvsQV4Opvm1CMZ'
      });
    },
    uri: '/public/legal-bases/1Y1KjWYCrvsQV4Opvm1CMZ'
  },
  footer: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/secciones/footer'
      });
    }
  },
  home: {
    getMobile: function getMobile() {
      return createEndpoint({
        method: methods.get,
        uri: '/secciones/mobile-home-body'
      });
    },
    // HOME-V1
    // getWeb: () =>
    //   createEndpoint({
    //     method: methods.get,
    //     uri: '/secciones/home-body',
    //   }),
    // HOME-V3
    getWeb: function getWeb() {
      return createEndpoint({
        method: methods.get,
        uri: '/public/home/223LiOKUVohvbhvR0BmOAv'
      });
    }
  },
  terminosCondiciones: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/public/terms-conditions/c6CjCUq8nrQ12lnDjilbi'
      });
    }
  },
  mensajes: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/public/home/223LiOKUVohvbhvR0BmOAv'
      });
    }
  },
  productos: {
    buscarQuery: function buscarQuery(_ref3) {
      var page = _ref3.page,
        size = _ref3.size,
        producto = _ref3.producto;
      return createEndpoint({
        method: methods.get,
        uri: "/public/products?query=".concat(encodeURI(producto), "&size=").concat(size, "&page=").concat(page)
      });
    },
    buscar: function buscar(_ref4) {
      var page = _ref4.page,
        size = _ref4.size,
        productName = _ref4.productName;
      return createProductosEndpoint({
        page: page,
        size: size,
        productName: productName,
        isDescendingOrder: false,
        from: '0',
        to: '300000'
      });
    },
    catalogo: {
      get: function get(_ref5) {
        var page = _ref5.page,
          size = _ref5.size,
          from = _ref5.from,
          to = _ref5.to,
          isDescendingOrder = _ref5.isDescendingOrder,
          categoria = _ref5.categoria,
          ofertas = _ref5.ofertas,
          nuevos = _ref5.nuevos,
          excluidos = _ref5.excluidos;
        return createProductosEndpoint({
          page: page,
          size: size,
          from: from,
          to: to,
          isDescendingOrder: isDescendingOrder,
          categoria: categoria,
          ofertas: ofertas,
          nuevos: nuevos,
          exclude: excluidos
        });
      },
      getBanners: function getBanners() {
        return createEndpoint({
          method: methods.get,
          uri: '/catalogo/banners'
        });
      }
    },
    tiendas: {
      get: function get(tienda, numpag, cantreg) {
        return createEndpoint({
          method: methods.get,
          uri: "/public/products-store?order=fields.points,fields.copayPrice&fields[store]=".concat(tienda, "&skip=").concat(numpag * cantreg, "&limit=").concat(cantreg)
        });
      },
      getBanner: function getBanner() {
        return createEndpoint({
          method: methods.get,
          uri: '/public/products-store' // Tienda banner?
        });
      }
    },
    // HOME-V3
    getCategorias: function getCategorias() {
      return createEndpoint({
        method: methods.get,
        uri: '/public/categories/tree'
      });
    },
    getNavBarCategories: function getNavBarCategories() {
      return createEndpoint({
        method: methods.get,
        uri: '/public/categories/tree'
      });
    },
    /**
     * @param {string} id
     * @return {ContentEndpoint}
     */
    getDetalles: function getDetalles(id) {
      return createEndpoint({
        method: methods.get,
        uri: '/public/products/{id}',
        uriParams: {
          id: id
        }
      });
    },
    getDestacados: function getDestacados() {
      return createProductosEndpoint({
        isDescendingOrder: false,
        ofertas: true,
        nuevos: true,
        size: 5
      });
    },
    getDetalleTienda: function getDetalleTienda(id) {
      return createEndpoint({
        method: methods.get,
        uri: '/public/products-store/{id}',
        uriParams: {
          id: id
        }
      });
    }
  },
  featureToggles: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/secciones/seccion-on-off'
      });
    }
  },
  landingPages: {
    get: function get(link) {
      return createEndpoint({
        method: methods.get,
        uri: "/secciones/landing/".concat(link)
      });
    }
  },
  landingInfo: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/public/basic-page/5s9b5TkKERlubCOWxD8wNw'
      });
    }
  },
  landingDigitalSecurity: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/public/basic-page/6dccPvRZFEzHCd4bhAw8LU'
      });
    }
  },
  basicPages: {
    get: function get(id) {
      return createEndpoint({
        method: methods.get,
        uri: "/public/basic-page/".concat(id)
      });
    }
  },
  ofertasHome: {
    get: function get() {
      return createEndpoint({
        method: methods.get,
        uri: '/public/personalized-offers/7BmkabF2sTyf0KZfiewN0j'
      });
    }
  },
  exchangeProducts: {
    get: function get(id) {
      return createEndpoint({
        method: methods.get,
        uri: "/public/exchange-products/".concat(id)
      });
    },
    getAll: function getAll(_ref6) {
      var page = _ref6.page,
        size = _ref6.size,
        from = _ref6.from,
        to = _ref6.to,
        exchangeType = _ref6.exchangeType,
        category = _ref6.category,
        store = _ref6.store,
        asc = _ref6.asc,
        query = _ref6.query;
      return createExchangeProductsEndpoint({
        page: page,
        size: size,
        from: from,
        to: to,
        exchangeType: exchangeType,
        category: category,
        store: store,
        asc: asc,
        query: query
      });
    }
  }
};